.react-multi-carousel-list {
    /* margin: 10px 10px; */
}

.react-multiple-carousel__arrow--right {
    right: calc(-0% + 1px);
}
.react-multiple-carousel__arrow--left {
    left: calc(-0% + 1px);
}
.react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 4px;
    z-index: 1000;
    border: 0;
    background: rgba(233,169,17,.78);;
    min-width: 24px;
    min-height: 25px;
    opacity: 1;
    cursor: pointer;
}
.react-multiple-carousel__arrow::before {
    font-size: 14px;
    font-weight:bold !important ;
}
.margin img {
    max-width: 100%;
    padding: 0px 5px;
}