@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. Fonts
2. Body and some general stuff
3. Header
4. Menu
5. Home
6. Discs
7. Artist
8. Milestones
9. Footer


******************************/

/***********
1. Fonts
***********/

/* @font-face
{
	font-family: 'Futura';
	src: url('../fonts/FuturaLT-Book.ttf')  format('truetype');
}
@font-face
{
	font-family: 'Futura_Bold';
	src: url('../fonts/FuturaLT-Bold.ttf')  format('truetype');
} */

/*********************************
2. Body and some general stuff
*********************************/

/* *
{
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
body
{
	font-family: 'Futura', sans-serif;
	font-size: 14px;
	font-weight: 400;
	background: #000000;
	color: #FFFFFF;
}
div
{
	display: block;
	position: relative;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
ul
{
	list-style: none;
	margin-bottom: 0px;
}
p
{
	font-family: 'Futura', sans-serif;
	font-size: 14px;
	line-height: 2.18;
	font-weight: 400;
	color: #FFFFFF;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a
{
	display: inline;
	position: relative;
	color: inherit;
	border-bottom: solid 1px #ffa07f;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
p:last-of-type
{
	margin-bottom: 0;
}
a
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
a, a:hover, a:visited, a:active, a:link
{
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a:active
{
	position: relative;
	color: #FF6347;
}
p a:hover
{
	color: #FFFFFF;
	background: #ffa07f;
}
p a:hover::after
{
	opacity: 0.2;
}
::selection
{
	background: rgba(255,255,255,0.07);
}
p::selection
{
	
}
h1{font-size: 48px;}
h2{font-size: 36px;}
h3{font-size: 24px;}
h4{font-size: 18px;}
h5{font-size: 14px;}
h1, h2, h3, h4, h5, h6
{
	font-family: 'Futura', sans-serif;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
	line-height: 1.2;
}
h1::selection, 
h2::selection, 
h3::selection, 
h4::selection, 
h5::selection, 
h6::selection
{
	
}
img
{
	max-width: 100%;
}
button:active
{
	outline: none;
}
.form-control
{
	color: #db5246;
}
section
{
	display: block;
	position: relative;
	box-sizing: border-box;
}
.clear
{
	clear: both;
}
.clearfix::before, .clearfix::after
{
	content: "";
	display: table;
}
.clearfix::after
{
	clear: both;
}
.clearfix
{
	zoom: 1;
}
.float_left
{
	float: left;
}
.float_right
{
	float: right;
}
.trans_200
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.trans_300
{
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.trans_400
{
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.trans_500
{
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.fill_height
{
	height: 100%;
}
.super_container
{
	width: 100%;
	overflow: hidden;
}
.prlx_parent
{
	overflow: hidden;
}
.prlx
{
	height: 130% !important;
}
.parallax-window
{
    min-height: 400px;
    background: transparent;
}
.parallax_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.background_image
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.nopadding
{
	padding: 0px !important;
}
.section_subtitle
{
	font-size: 18px;
	line-height: 0.75;
}
.section_title
{
	margin-top: 7px;
	margin-left: -2px;
} */

/*********************************
3. Header
*********************************/

/* .header
{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background: #000000;
	z-index: 100;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.header.scrolled
{
	background: rgba(0,0,0,0.85);
}
.header_content
{
	width: 100%;
	height: 101px;
	padding-left: 51px;
	padding-right: 52px;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.header.scrolled .header_content
{
	height: 70px;
}
.logo
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 51px;
}
.logo a
{
	font-family: 'Futura', sans-serif;
	font-size: 30px;
	color: #FFFFFF;
}
.log_reg
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 52px;
}
.log_reg ul li
{
	position: relative;
}
.log_reg ul li:not(:last-child)::after
{
	display: inline-block;
	content: '/';
	font-size: 14px;
	color: #FFFFFF;
	margin-left: 6px;
	margin-right: 6px;
}
.log_reg ul li a
{
	font-size: 14px;
	color: #FFFFFF;
}
.log_reg ul li a:hover
{
	color: #de4f33;
}
.main_nav ul li:not(:last-child)
{
	margin-right: 24px;
}
.main_nav ul li
{
	position: relative;
}
.main_nav ul li a
{
	position: relative;
	font-size: 16px;
	color: #FFFFFF;
	padding-left: 26px;
	padding-right: 23px;
	z-index: 1;
}
.main_nav ul li a::after
{
	display: block;
	position: absolute;
	left: 0;
	bottom: -20px;
	width: 100%;
	height: 17px;
	background: #525252;
	content: '';
	z-index: -1;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.main_nav ul li.active a::after,
.main_nav ul li a:hover::after
{
	bottom: -5px;
	visibility: visible;
	opacity: 1;
}
.hamburger
{
	display: none;
	width: 50px;
	height: 24px;
	cursor: pointer;
	padding: 5px;
	right: -5px;
}
.hamburger > div
{
	width: 100%;
	height: 100%;
}
.hamburger > div > div
{
	height: 2px;
	background: #FFFFFF;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.hamburger > div > div:first-child
{
	width: 90%;
}
.hamburger > div > div:nth-child(2)
{
	width: 50%;
}
.hamburger > div > div:nth-child(3)
{
	width: 70%;
}
.hamburger:hover > div > div
{
	width: 100%;
} */

/*********************************
4. Menu
*********************************/

/* .menu
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 101;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 800ms ease;
	-moz-transition: all 800ms ease;
	-ms-transition: all 800ms ease;
	-o-transition: all 800ms ease;
	transition: all 800ms ease;
}
.menu_overlay
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.45);
}
.menu.active
{
	visibility: visible;
	opacity: 1;
}
.menu > div
{
	width: 100%;
	height: 100%;
}
.menu_container
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: -300px;
	width: 300px;
	-webkit-transition: all 1000ms 600ms ease;
	-moz-transition: all 1000ms 600ms ease;
	-ms-transition: all 1000ms 600ms ease;
	-o-transition: all 1000ms 600ms ease;
	transition: all 1000ms 600ms ease;
}
.menu.active .menu_container
{
	right: 0;
}
.menu_log_reg
{
	display: none;
	width: 100%;
	background: #000000;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 8px;
	padding-bottom: 8px;
	margin-bottom: 5px;
	border-bottom: solid 2px #de4f32;
}
.menu_log_reg a
{
	font-size: 14px;
	color: #FFFFFF;
}
.menu_log_reg a:hover
{
	color: #de4f32;
}
.menu_log_reg ul li
{
	position: relative;
}
.menu_log_reg ul li:not(:last-child)::after
{
	display: inline-block;
	content: '/';
	font-size: 14px;
	color: #FFFFFF;
	margin-left: 6px;
	margin-right: 6px;
}
.menu_nav
{
	width: 100%;
	background: #000000;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 50px;
	padding-bottom: 50px;
	border-bottom: solid 5px #de4f32;
}
.menu_nav ul li
{
	position: relative;
}
.menu_nav ul li a:hover::before
{
	left: 100%;
}
.menu_nav ul li:not(:last-of-type)
{
	margin-bottom: 5px;
}
.menu_nav ul li a
{
	display: block;
	position: relative;
	font-size: 20px;
	color: #FFFFFF;
	overflow: hidden;
}
.menu_nav ul li a::before
{
	display: block;
	position: absolute;
	top: 50%;
	left: -100%;
	width: 100%;
	height: 2px;
	background: #FFFFFF;
	content: '';
	-webkit-transition: all 400ms 200ms ease;
	-moz-transition: all 400ms 200ms ease;
	-ms-transition: all 400ms 200ms ease;
	-o-transition: all 400ms 200ms ease;
	transition: all 400ms 200ms ease;
}
.menu_nav ul li a:hover
{
	color: #de4f32;
} */

/*********************************
5. Home
*********************************/

/* .home
{
	width: 100%;
	height: 384px;
	padding-left: 51px;
	padding-right: 52px;
}
.home_inner
{
	width: 100%;
	height: 100%;
}
.home_container
{
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	bottom: 89px;
}
.home_subtitle
{
	font-size: 18px;
	line-height: 0.75;
}
.home_title
{
	font-size: 82px;
	line-height: 0.75;
	margin-top: 14px;
} */

/*********************************
6. Discs
*********************************/

.discs
{
	background: #000000;
	padding-top: 105px;
	padding-bottom: 78px;
}
.discs_row > div[class^='col']
{
	margin-bottom: 30px;
}
.disc a
{
	display: block;
	position: relative;
	overflow: hidden;
}
.disc a::before
{
	display: block;
	position: absolute;
	top: -100%;
	left: -200%;
	transform-origin: right center;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	background: rgba(222,79,50,0.7);
	width: 200%;
	height: 200%;
	z-index: 1;
	content: '';
	-webkit-transition: all 600ms ease;
	-moz-transition: all 600ms ease;
	-ms-transition: all 600ms ease;
	-o-transition: all 600ms ease;
	transition: all 600ms ease;
}
.disc:hover a::before
{
	-webkit-transform: translate(60%, 60%) rotate(45deg);
	-moz-transform: translate(60%, 60%) rotate(45deg);
	-ms-transform: translate(60%, 60%) rotate(45deg);
	-o-transform: translate(60%, 60%) rotate(45deg);
	transform: translate(60%, 60%) rotate(45deg);
}
.disc_image
{
	width: 100%;
}
.disc_container
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}
.disc_container > div
{
	width: 100%;
	height: 100%;
}
.disc_content_1,
.disc_content_3
{
	position: absolute;
	left: 48px;
	bottom: 38px;
}
.disc_title
{
	font-family: 'Futura_Bold', sans-serif;
}
.disc_content_1 .disc_title
{
	font-size: 36px;
	color: #FFFFFF;
}
.disc_content_1 .disc_subtitle
{
	font-size: 18px;
	line-height: 1.2;
	margin-top: -4px;
	color: #FFFFFF;
}
.disc_content_2
{
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	bottom: 27px;
	width: 246px;
	/*height: 149px;*/
	padding-top: 39px;
	padding-bottom: 45px;
	background: rgba(0,0,0,0.48);
}
.disc_content_2 .disc_title
{
	font-family: 'Futura_Bold', sans-serif;
	font-size: 36px;
	color: #FFFFFF;
	line-height: 1.2;
}
.disc_content_2 .disc_subtitle
{
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.2em;
	color: #FFFFFF;
	line-height: 1.2;
	margin-top: 8px;
}
.disc_content_3 .disc_title
{
	font-size: 24px;
	color: #FFFFFF;
	line-height: 1.2;
}
.disc_content_3 .disc_subtitle
{
	font-size: 12px;
	color: #FFFFFF;
	line-height: 1.2;
	margin-top: 5px;
}
.disc_content_4
{
	position: absolute;
	top: 10px;
	right: 10px;
	height: calc(100% - 18px);
	padding-right: 12px;
	padding-bottom: 11px;
	padding-left: 28px;
	background: rgba(0,0,0,0.54);
}
.disc_content_4 .disc_title
{
	font-size: 18px;
	color: #FFFFFF;
	line-height: 1.2;
}
.disc_content_4 .disc_subtitle
{
	font-size: 36px;
	color: #FFFFFF;
	margin-left: -5px;
	line-height: 1.2;
	margin-top: 3px;
}
.disc_content_5
{
	position: absolute;
	left: 100%;
	top: 0;
	transform-origin: top left;
	transform: rotate(90deg);
}
.disc_content_5 .disc_title
{
	font-size: 81px;
	color: #FFFFFF;
	line-height: 1.2;
}
.disc_content_6
{
	position: absolute;
	top: 38px;
	right: 29px;
}
.disc_content_6 .disc_title
{
	font-size: 36px;
	line-height: 1.2;
	color: #FFFFFF;
}
.disc_content_6 .disc_subtitle
{
	font-size: 18px;
	color: #FFFFFF;
	line-height: 1.2;
	margin-top: 2px;
}

/*********************************
7. Artist
*********************************/

.artist
{
	padding-top: 120px;
	padding-bottom: 134px;
}
.artist_text
{
	margin-top: 39px;
}
.artist_text p:not(:last-child)
{
	margin-bottom: 30px;
}
.artist_sig
{
	margin-top: 51px;
	margin-left: 26px;
}
.single_player_container
{
	width: calc(100% + 12px);
	background: #FFFFFF;
	left: -10px;
	margin-top: 62px;
}
.single_player
{
	width: 100%;
}
.jp-type-single .player_details
{
	margin-top: 0;
	padding-left: 46px;
	padding-right: 54px;
}
.jp-type-single .player_controls .jp-interface
{
	padding-left: 44px;
	padding-right: 52px;
}
.jp-type-single .jp-play
{
	-webkit-transform: translateY(7px);
	-moz-transform: translateY(7px);
	-ms-transform: translateY(7px);
	-o-transform: translateY(7px);
	transform: translateY(7px);
}
.jp-audio
{
	width: 100%;
}
.song_duration
{
	margin-left: auto;
}
.song_duration
{
	font-family: 'Futura', sans-serif !important;
	font-size: 16px !important;
	color: rgba(255,255,255,0.5) !important;
	line-height: 38px;
	outline: none !important;
}
.jp-details
{
	display: block !important;
}
.player_details
{
	width: 100%;
	height: 92px;
	background: #FFFFFF;
	margin-top: 27px;
	padding-left: 25px;
	padding-right: 22px;
}
.jp-details > div
{
	font-size: 12px;
	color: rgba(51,50,50,0.5);
	text-transform: uppercase;
}
.jp-details > div:first-child
{
	display: none;
}
.jp-state-playing .jp-details > div:first-child
{
	display: block;
}
.jp-type-single .jp-state-playing .jp-details .jp-title
{
	display: block;
}
.jp-title
{
	font-size: 16px !important;
	color: #333232 !important;
	text-transform: none !important;
}
.jp-play
{
	position: relative;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background: #de4f32;
	border: none;
	cursor: pointer;
	text-align: center;
	outline: none !important;
}
.jp-play::after
{
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-family: 'FontAwesome';
	font-size: 20px;
	color: #FFFFFF;
	content: '\f04b';
}
.jp-state-playing .jp-play::after
{
	content: '\f04c';
}
.jp-play:active
{
	width: 46px;
	height: 46px;
}
.player_controls
{
	width: 100%;
	background: #ececec;
}
.player_controls .jp-interface
{
	width: 100%;
	height: 56px;
	padding-left: 23px;
	padding-right: 20px;
}
.jp-volume-controls
{
	width: 80px;
}
.jp-volume-bar
{
	width: 45px;
	height: 9px;
	background: #989898;
	cursor: pointer;
}
.jp-volume-bar-value
{
	height: 9px;
	background: #5f5f5f;
}
.jp-mute
{
	position: relative;
	width: 29px;
	height: 16px;
	background: transparent;
	border: none;
	outline: none;
	margin-right: 6px;
	cursor: pointer;
}
.jp-state-muted .jp-mute::after
{
	content: url(../images/mute_0.png);
}
.jp-mute:active
{
	outline: none;
}
.jp-mute::after
{
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: url(../images/mute_1.png);
}
.time_controls
{
	width: calc(100% - 110px);
}
.jp-progress
{
	width: 100%;
	height: 9px;
	background: #9f9f9f;
}
.jp-current-time
{
	width: 48px;
}
.jp-current-time,
.jp-duration
{
	font-size: 14px;
	color: #333232 !important;
}
.jp-duration
{
	width: 56px;
	text-align: right;
}
.jp-progress
{
	cursor: pointer;
}
.jp-play-bar
{
	height: 9px;
	background: #5f5f5f;
}

/*********************************
8. Milestones
*********************************/
.milestones{
    background: url(../images/milestones.jpg) center center no-repeat;
    /* height: 100vh; */
    background-size: cover;
  }
.milestones
{
	padding-top: 108px;
	padding-left: 50px;
	padding-right: 52px;
}
.milestones_container
{
	padding-top: 180px;
	padding-bottom: 107px;
}
.milestone_col
{
	margin-bottom: 80px;
}
.milestone_icon
{
	width: 48px;
	height: 48px;
}
.milestone_icon img
{
	max-width: 100%;
}
.milestone_content
{
	padding-left: 26px;
}
.milestone_counter
{
	font-size: 40px;
	color: #FFFFFF;
	line-height: 0.75;
}
.milestone_text
{
	font-size: 14px;
	color: #FFFFFF;
	line-height: 0.75;
	margin-top: 24px;
}

/*********************************
9. Footer
*********************************/

/* .footer
{
	background: #000000;
	padding-left: 50px;
	padding-right: 51px;
}
.newsletter_container
{
	width: 604px;
	padding-top: 103px;
}
.newsletter_title
{

}
.newsletter_form
{
	display: block;
	position: relative;
	width: 100%;
	margin-top: 30px;
}
.newsletter_input
{
	width: 100%;
	height: 50px;
	background: #545454;
	border: none;
	outline: none;
	padding-left: 23px;
}
.newsletter_input::-webkit-input-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #FFFFFF !important;
}
.newsletter_input:-moz-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #FFFFFF !important;
}
.newsletter_input::-moz-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #FFFFFF !important;
} 
.newsletter_input:-ms-input-placeholder
{ 
	font-size: 12px !important;
	font-style: italic;
	color: #FFFFFF !important;
}
.newsletter_input::input-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #FFFFFF !important;
}
.newsletter_button
{
	position: absolute;
	top: 0;
	right: 0;
	width: 139px;
	height: 50px;
	background: #de4f32;
	border: none;
	outline: none;
	cursor: pointer;
	color: #FFFFFF;
	font-size: 14px;
}
.footer_lists
{
	width: 575px;
	padding-top: 83px;
	padding-bottom: 5px;
}
.footer_list
{
	width: calc(100% / 3);
}
.footer_list_title
{
	font-size: 24px;
}
.footer_list ul
{
	margin-top: 23px;
}
.footer_list ul li:not(:last-of-type)
{
	margin-bottom: 9px;
}
.footer_list ul li a
{
	font-size: 14px;
	color: #6d6d6d;
}
.footer_list ul li a:hover
{
	color: #FFFFFF;
}
.copyright_bar
{
	width: 100%;
	height: 67px;
	background: #000000;
}
.copyright_bar span
{
	font-size: 14px;
	color: #6d6d6d;
	line-height: 67px;
} */