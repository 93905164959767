@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. Fonts
2. Body and some general stuff
3. Header
4. Menu
5. Home
6. Featured Album
7. Shows
8. Artist
9. Extra
10. Footer


******************************/
::-moz-selection {  
	color: white; 
	background: #e9a911 !important; 
} 
  
::selection { 
	color: white; 
	background: #e9a911 !important; 
} 

.home_content{
	position: absolute;
	top: 50%;
	left: 50%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
  }
  .themeimage{
	  /* position: relative;
	  max-width: 100%;
	  height: 100vh; */
	  
  }
  .themeimage img{
	/* position: absolute;
	max-width: 100%;
	height: 100%; */
	
  }
  .themeimage{
	background: url(../images/index.jpg) center center no-repeat;
	height: 80vh;
	background-size: cover;
  }
  @keyframes App-logo-spin {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }
/***********
1. Fonts
***********/

@font-face
{
	font-family: 'Futura';
	src: url('../fonts/FuturaLT-Book.ttf')  format('truetype');
}

/*********************************
2. Body and some general stuff
*********************************/

*
{
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
body
{
	font-family: 'Futura', sans-serif;
	font-size: 14px;
	font-weight: 400;
	background: #000000;
	color: #FFFFFF;
}
div
{
	display: block;
	position: relative;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
ul
{
	list-style: none;
	margin-bottom: 0px;
}
p
{
	font-family: 'Futura', sans-serif;
	font-size: 14px;
	line-height: 2.18;
	font-weight: 400;
	color: #FFFFFF;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a
{
	display: inline;
	position: relative;
	color: inherit;
	border-bottom: solid 1px #ffa07f;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
p:last-of-type
{
	margin-bottom: 0;
}
a
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
a, a:hover, a:visited, a:active, a:link
{
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a:active
{
	position: relative;
	color: #FF6347;
}
p a:hover
{
	color: #FFFFFF;
	background: #ffa07f;
}
p a:hover::after
{
	opacity: 0.2;
}
::selection
{
	background: rgba(255,255,255,0.07);
}

h1{font-size: 48px;}
h2{font-size: 36px;}
h3{font-size: 24px;}
h4{font-size: 18px;}
h5{font-size: 14px;}
h1, h2, h3, h4, h5, h6
{
	font-family: 'Futura', sans-serif;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(245, 239, 239, 0.925) 0 0 1px;
	text-shadow: rgba(245, 242, 242, 0.01) 0 0 1px;
	line-height: 1.2;
}

button:active
{
	outline: none;
}
.form-control
{
	color: #db5246;
}
section
{
	display: block;
	position: relative;
	box-sizing: border-box;
}
.clear
{
	clear: both;
}
.clearfix::before, .clearfix::after
{
	content: "";
	display: table;
}
.clearfix::after
{
	clear: both;
}
.clearfix
{
	zoom: 1;
}
.float_left
{
	float: left;
}
.float_right
{
	float: right;
}
.trans_200
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.trans_300
{
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.trans_400
{
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.trans_500
{
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.fill_height
{
	height: 100%;
}
.super_container
{
	width: 100%;
	overflow: hidden;
}
.prlx_parent
{
	overflow: hidden;
}
.prlx
{
	height: 130% !important;
}
.parallax-window
{
    min-height: 400px;
    background: transparent;
}
.parallax_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.background_image
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.nopadding
{
	padding: 0px !important;
}
.section_subtitle
{
	font-size: 18px;
	line-height: 0.75;
}
.section_title
{
	margin-top: 7px;
	margin-left: -2px;
}

/*********************************
3. Header
*********************************/

.header
{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.69);
	z-index: 1001;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.header.scrolled
{
	background: rgba(0,0,0,0.85);
}
.header_content
{
	width: 100%;
	height: 101px;
	padding-left: 51px;
	padding-right: 52px;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.header.scrolled .header_content
{
	height: 70px;
}
.logo
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 51px;
}
.logo a
{
	font-family: 'Futura', sans-serif;
	font-size: 30px;
	color: #FFFFFF;
}
.log_reg
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 52px;
}
.log_reg ul li
{
	position: relative;
}
.log_reg ul li:not(:last-child)::after
{
	display: inline-block;
	content: '/';
	font-size: 14px;
	color: #FFFFFF;
	margin-left: 6px;
	margin-right: 6px;
}
.log_reg ul li a
{
	font-size: 14px;
	color: #FFFFFF;
}
.log_reg ul li a:hover
{
	color: #e9a911;
}
.main_nav ul li:not(:last-child)
{
	margin-right: 24px;
}
.main_nav ul li
{
	position: relative;
}
.main_nav ul li a
{
	position: relative;
	font-size: 16px;
	color: #FFFFFF;
	padding-left: 26px;
	padding-right: 23px;
	z-index: 1;
}
.main_nav ul li a::after
{
	display: block;
	position: absolute;
	left: 0;
	bottom: -20px;
	width: 100%;
	height: 17px;
	background: #e9a911;
	content: '';
	z-index: -1;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.main_nav ul li.active ,
.main_nav ul li 
{
	bottom: -5px;
	visibility: visible;
	opacity: 1;
}
.hamburger
{
	display: none;
	width: 50px;
	height: 24px;
	cursor: pointer;
	padding: 5px;
	right: -5px;
}
.hamburger > div
{
	width: 100%;
	height: 100%;
}
.hamburger > div > div
{
	height: 2px;
	background: #FFFFFF;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.hamburger > div > div:first-child
{
	width: 100%;
}
.hamburger > div > div:nth-child(2)
{
	width: 100%;
}
.hamburger > div > div:nth-child(3)
{
	width: 100%;
}


/*********************************
4. Menu
*********************************/

.menu
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 101;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 800ms ease;
	-moz-transition: all 800ms ease;
	-ms-transition: all 800ms ease;
	-o-transition: all 800ms ease;
	transition: all 800ms ease;
}
.menu_overlay
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.45);
}
.menu.active
{
	visibility: visible;
	opacity: 1;
	z-index: 1003;
}
.menu > div
{
	width: 100%;
	height: 100%;
}
.menu_container
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: -300px;
	width: 300px;
	-webkit-transition: all 1000ms 600ms ease;
	-moz-transition: all 1000ms 600ms ease;
	-ms-transition: all 1000ms 600ms ease;
	-o-transition: all 1000ms 600ms ease;
	transition: all 1000ms 600ms ease;
}
.menu.active .menu_container
{
	right: 0;
}
.menu_log_reg
{
	display: none;
	width: 100%;
	background: #000000;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 8px;
	padding-bottom: 8px;
	margin-bottom: 5px;
	border-bottom: solid 2px #e9a911;
}
.menu_log_reg a
{
	font-size: 14px;
	color: #FFFFFF;
}
.menu_log_reg a:hover
{
	color: #e9a911;
}
.menu_log_reg ul li
{
	position: relative;
}
.menu_log_reg ul li:not(:last-child)::after
{
	display: inline-block;
	content: '/';
	font-size: 14px;
	color: #FFFFFF;
	margin-left: 6px;
	margin-right: 6px;
}
.menu_nav
{
	width: 100%;
	background: #000000;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 50px;
	padding-bottom: 50px;
	border-bottom: solid 5px #e9a911;
}
.menu_nav ul li
{
	position: relative;
}
.menu_nav ul li a:hover::before
{
	left: 100%;
}
.menu_nav ul li:not(:last-of-type)
{
	margin-bottom: 5px;
}
.menu_nav ul li a
{
	display: block;
	position: relative;
	font-size: 20px;
	color: #FFFFFF;
	overflow: hidden;
}
.menu_nav ul li a::before
{
	display: block;
	position: absolute;
	top: 50%;
	left: -100%;
	width: 100%;
	height: 2px;
	background: #FFFFFF;
	content: '';
	/* -webkit-transition: all 400ms 200ms ease;
	-moz-transition: all 400ms 200ms ease;
	-ms-transition: all 400ms 200ms ease;
	-o-transition: all 400ms 200ms ease;
	transition: all 400ms 200ms ease; */
}
.menu_nav ul li a:hover
{
	color: #e9a911;
}

/*********************************
5. Home
*********************************/

.home
{
	width: 100%;
	/* height: 892px ; */
	padding-left: 51px;
	padding-right: 52px;
}
.SearchArea {
	/* width: 50%; */
	/* padding-left: 51px;
	padding-right: 52px; */
}
.home_slider_container
{
	width: 100%;
	height: 100%;
}
.home_slider.owl-theme .owl-nav.disabled + .owl-dots
{
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	bottom: 24px;
	z-index: 10;
}
.home_slider.owl-theme .owl-dots .owl-dot span
{
	width: 14px;
	height: 14px;
	background: #4d4d4d;
	border-radius: 0px;
	margin-left: 3px;
	margin-right: 3px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.home_slider.owl-theme .owl-dots .owl-dot.active span,
.home_slider.owl-theme .owl-dots .owl-dot:hover span
{
	background: #000000;
}
.home_container
{
	width: 100%;
	height: 100%;
	z-index: 1;
	padding-top: 72px;
}
.home_container_inner
{
	width: 100%;
	height: 100%;
}

.home_subtitle
{
	font-size: 18px;
	color: #FFFFFF;
	text-shadow: 1px 3px 5px #da29f7;
}
.home_title
{
	margin-top: 24px;
}
.home_title h1
{
	font-size: 82px;
	color: #FFFFFF;
	text-shadow: 3px 2px #da29f7;
}
.home_link
{
	margin-top: 21px;
}
.home_link a
{
	position: relative;
	padding-left: 17px;
	padding-right: 12px;
	font-size: 24px;
	color: #FFFFFF;
}
.home_link a::before
{
	display: block;
	position: absolute;
	left: 0;
	bottom: -4px;
	width: 100%;
	height: 22px;
	background: #000000;
	content: '';
	z-index: -1;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.home_link a:hover::before
{
	background: #e9a911;
}

/*********************************
6. Featured Album
*********************************/

.featured_album
{
	/* padding-top: 107px; */
	padding-top: 25px;
}
.featured_background
{
	top: auto;
	bottom: -185px;
	max-height: 489px;
}
.featured_album .section_title_container
{
	padding-left: 20px;
}
.featured_row
{
	margin-top: 71px;
}
.featured_album_image
{
	width: calc(100% + 42px);
	height: 722px;
}
.featured_album_image img
{
	max-width: 100%;
}
.image_overlay
{
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	/* background: #e9a911; */
	z-index: 1;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 1000ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1200ms ease;
}
.image_overlay.active
{
	width: 0%;
}
.featured_album_col
{
	z-index: 1;
}
.featured_album_player_container
{
	width: calc(100% + 58px);
	left: -58px;
	height: 100%;
}
.featured_album_player
{
	width: 100%;
	border: solid 1px #d3d3d3;
	background: #333232;
}
.featured_album_title_bar
{
	height: 146px;
	padding-left: 55px;
	padding-right: 46px;
}

.featured_album_artist
{
	font-size: 30px;
	color: #FFFFFF;
	line-height: 1.2;
}
.featured_album_title
{
	font-size: 16px;
	color: rgba(255,255,255,0.67);
	margin-top: -2px;
}
.featured_album_link
{
	width: 181px;
	height: 42px;
	background: #e9a911;
	text-align: center;
}
.featured_album_link a
{
	display: block;
	width: 100%;
	height: 100%;
	font-size: 12px;
	color: #FFFFFF;
	text-transform: uppercase;
	line-height: 42px;
}
.jp-audio
{
	width: 100%;
}
.jp-playlist
{
	background: #333232;
}
/* .jp-playlist ul
{
	padding-left: 55px;
} */
.jp-playlist li
{
	position: relative;
	height: 38px;
	border-bottom: none;
	padding-left: 55px;
}
.jp-playlist li::before,
#jp_container_1.jp-state-playing .jp-playlist li.jp-playlist-current::before
{
	display: block;
	position: absolute;
	top: 0;
	left: -4px;
	width: calc(100% + 8px);
	height: 100%;
	background: #5c5c5c;
	visibility: hidden;
	opacity: 0;
	content: '';
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.jp-playlist li:hover::before,
#jp_container_1.jp-state-playing .jp-playlist li.jp-playlist-current::before
{
	opacity: 1;
	visibility: visible;
}
.jp-playlist li > div
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	/* justify-content: start; */
	align-items: center;
	padding-left: 35px;
	padding-right: 45px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.song_duration
{
	margin-left: auto;
}
.jp-playlist li > div::before
{
	display: block;
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	font-family: 'FontAwesome';
	content: '\f04b';
	color: rgba(255,255,255,0.5);
}
.jp-playlist li:not(:last-child)
{
	margin-bottom: 14px;
}
.jp-type-playlist div.jp-playlist a,
.song_duration
{
	font-family: 'Futura', sans-serif !important;
	font-size: 16px !important;
	color: rgba(255,255,255,0.5) !important;
	line-height: 38px;
	outline: none !important;
}
.jp-type-playlist div.jp-playlist a > span
{
	display: none;
}
#jp_container_1.jp-state-playing .jp-playlist li.jp-playlist-current > div
{
	padding-left: 26px;
}
#jp_container_1.jp-state-playing .jp-playlist li.jp-playlist-current > div::before
{
	color: #FFFFFF;
}
#jp_container_1.jp-state-playing .jp-playlist li.jp-playlist-current a,
#jp_container_1.jp-state-playing .jp-playlist li.jp-playlist-current > div .song_duration
{
	color: #FFFFFF !important;
}
.jp-details
{
	display: block !important;
}
.player_details
{
	width: 100%;
	height: 92px;
	background: #FFFFFF;
	margin-top: 27px;
	padding-left: 25px;
	padding-right: 22px;
}
.jp-details > div
{
	font-size: 12px;
	color: rgba(51,50,50,0.5);
	text-transform: uppercase;
}

.jp-details > div:first-child
{
	display: none;
}
.jp-state-playing .jp-details > div:first-child
{
	display: block;
}
.jp-state-playing .jp-details .jp-title
{
	display: block;
}
.jp-state-playing .jp-details > div:first-child
{
	display: block !important;
}
.jp-title
{
	font-size: 16px !important;
	color: #333232 !important;
	text-transform: none !important;
}
.jp-play
{
	position: relative;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background: #e9a911;
	border: none;
	cursor: pointer;
	text-align: center;
	outline: none !important;
}
.jp-play::after
{
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-family: 'FontAwesome';
	font-size: 20px;
	color: #FFFFFF;
	content: '\f04b';
}
.jp-state-playing .jp-play::after
{
	content: '\f04c';
}
.jp-play:active
{
	width: 46px;
	height: 46px;
}
.player_controls
{
	width: 100%;
	background: #ececec;
}
.player_controls .jp-interface
{
	width: 100%;
	height: 56px;
	padding-left: 23px;
	padding-right: 20px;
}
.jp-volume-controls
{
	width: 80px;
}
.jp-volume-bar
{
	width: 45px;
	height: 9px;
	background: #989898;
	cursor: pointer;
}
.jp-volume-bar-value
{
	height: 9px;
	background: #5f5f5f;
}
.jp-mute
{
	position: relative;
	width: 29px;
	height: 16px;
	background: transparent;
	border: none;
	outline: none;
	margin-right: 6px;
	cursor: pointer;
}
.jp-state-muted .jp-mute::after
{
	content: url(../images/mute_0.png);
}
.jp-mute:active
{
	outline: none;
}
.jp-mute::after
{
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: url(../images/mute_1.png);
}
.time_controls
{
	width: calc(100% - 110px);
}
.jp-progress
{
	width: 100%;
	height: 9px;
	background: #9f9f9f;
}
.jp-current-time
{
	width: 48px;
}
.jp-current-time,
.jp-duration
{
	font-size: 14px;
	color: #333232 !important;
}
.jp-duration
{
	width: 56px;
	text-align: right;
}
.jp-progress
{
	cursor: pointer;
}
.jp-play-bar
{
	height: 9px;
	background: #5f5f5f;
}

/*********************************
7. Shows
*********************************/

.shows
{
	padding-top: 175px;
}
.shows_row
{
	margin-top: -55px;
}
.shows_list_col
{
	z-index: 1;
}
.shows_image
{
	left: -98px;
	width: calc(100% + 252px);
}
.shows_image img
{
	max-width: 100%;
}
.shows_image .image_overlay
{
	z-index: 0;
}
.shows_list_container
{
	width: calc(100% + 13px);
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}
.shows_list li
{
	width: 100%;
	height: 81px;
	background: #333232;
	padding-left: 11px;
	padding-right: 37px;
	color: #FFFFFF;
	font-size: 18px;
}
.shows_list li a
{
	color: #FFFFFF;
}
.shows_list li:not(:last-of-type)
{
	margin-bottom: 5px;
}
.show_date
{
	width: 78px;
	height: 60px;
	background: #e9a911;
	line-height: 60px;
	text-align: center;
	margin-right: 23px;
}
.show_info
{
	width: 100%;
	height: 100%;
	padding-right: 23px;
}
.show_name
{
	width: 50%;
	margin-right: 23px;
}
.show_name a:hover
{
	color: #e9a911;
}
.show_location
{
	width: calc(50% - 23px);
	margin-left: 23px;
}
.show_shop
{
	width: 120px;
	height: 35px;
	background: #000000;
}
.show_shop:hover
{
	background: #e9a911;
}
.show_shop a
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 35px;
	text-align: center;
}

/*********************************
8. Artist
*********************************/

.artist
{
	padding-bottom: 106px;
	background: #000000;
}
.artist_content
{
	padding-top: 191px;
}
.artist_image
{
	width: calc(100% + 205px);
	left: -185px;
	padding-right: 20px;
}
.artist_image img
{
	max-width: 100%;
}
.artist_text
{
	margin-top: 39px;
}
.artist_text p:not(:last-child)
{
	margin-bottom: 30px;
}
.artist_sig
{
	margin-top: 51px;
	margin-left: 26px;
}
.single_player_container
{
	width: calc(100% + 12px);
	background: #FFFFFF;
	left: -10px;
	margin-top: 62px;
}
.single_player
{
	width: 100%;
}
.jp-type-single .player_details
{
	margin-top: 0;
	padding-left: 46px;
	padding-right: 54px;
}
.jp-type-single .player_controls .jp-interface
{
	padding-left: 44px;
	padding-right: 52px;
}
.jp-type-single .jp-play
{
	-webkit-transform: translateY(7px);
	-moz-transform: translateY(7px);
	-ms-transform: translateY(7px);
	-o-transform: translateY(7px);
	transform: translateY(7px);
}

/*********************************
9. Extra
*********************************/

.extra
{
	padding-left: 50px;
	padding-right: 51px;
}

.extra_content
{
	height: 448px;
	-webkit-transform: translateY(-5px);
	-moz-transform: translateY(-5px);
	-ms-transform: translateY(-5px);
	-o-transform: translateY(-5px);
	transform: translateY(-5px);
}
.extra_title h1
{
	font-size: 60px;
}
.extra_text
{
	margin-top: 4px;
}
.extra_button
{
	width: 161px;
	height: 46px;
	background: #000000;
	margin-top: 35px;
}
.extra_button:hover
{
	background: rgba(0,0,0,0.5);
}
.extra_button a
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 46px;
	color: #FFFFFF;
	font-size: 14px;
	text-align: center;
}

/*********************************
10. Footer
*********************************/

.footer
{
	background: #000000;
	padding-left: 50px;
	padding-right: 51px;
}
.newsletter_container
{
	width: 604px;
	padding-top: 103px;
}
.newsletter_form
{
	display: block;
	position: relative;
	width: 100%;
	margin-top: 30px;
}
.newsletter_input
{
	width: 100%;
	height: 50px;
	background: #545454;
	border: none;
	outline: none;
	padding-left: 23px;
}
.newsletter_input::-webkit-input-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #FFFFFF !important;
}
.newsletter_input:-moz-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #FFFFFF !important;
}
.newsletter_input::-moz-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #FFFFFF !important;
} 
.newsletter_input:-ms-input-placeholder
{ 
	font-size: 12px !important;
	font-style: italic;
	color: #FFFFFF !important;
}
.newsletter_input::input-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #FFFFFF !important;
}
.newsletter_button
{
	position: absolute;
	top: 0;
	right: 0;
	width: 139px;
	height: 50px;
	background: #e9a911;
	border: none;
	outline: none;
	cursor: pointer;
	color: #FFFFFF;
	font-size: 14px;
}
.footer_lists
{
	width: 575px;
	padding-top: 83px;
	padding-bottom: 5px;
}
.footer_list
{
	width: calc(100% / 3);
}
.footer_list_title
{
	font-size: 24px;
}
.footer_list ul
{
	margin-top: 23px;
}
.footer_list ul li:not(:last-of-type)
{
	margin-bottom: 9px;
}
.footer_list ul li a
{
	font-size: 14px;
	color: #6d6d6d;
}
.footer_list ul li a:hover
{
	color: #FFFFFF;
}
.copyright_bar
{
	width: 100%;
	height: 67px;
	background: #000000;
}
.copyright_bar span
{
	font-size: 14px;
	color: #6d6d6d;
	line-height: 67px;
}
.socialLinks{
	display:flex;
	align-items: center;
	padding: 10px 0px 0px 0px;
	
}
/* .textLeft{
	text-align: right;
} */
.groupLink1{
	margin-left:auto;}
.groupLink2{
	margin-left:10px;
	cursor: pointer;
}
.footer_list_title1{
	font-size: 18px;
}
.groupLink3{
	margin-left:15px;
	cursor: pointer;

}



/* @charset "utf-8"; */
/* CSS Document */

/******************************

[Table of Contents]

1. 1600px
2. 1440px
3. 1280px
4. 1199px
5. 1024px
6. 991px
7. 959px
8. 880px
9. 768px
10. 767px
11. 539px
12. 479px
13. 400px

******************************/

/************
1. 1600px
************/

@media only screen and (max-width: 1600px)
{
	
}

/************
2. 1440px
************/

@media only screen and (max-width: 1440px)
{
	
}

/************
3. 1380px
************/

@media only screen and (max-width: 1380px)
{
	.newsletter_container
	{
		width: 450px;
	}
	.footer_lists
	{
		width: 575px;
	}
}

/************
3. 1280px
************/

@media only screen and (max-width: 1280px)
{
	
}

/************
4. 1199px
************/

@media only screen and (max-width: 1199px)
{
	.shows_image
	{
		left: -198px;
	}
	.footer_lists
	{
		width: 667px;
	}
	.copyright_bar
	{
		margin-top: 60px;
	}
	.newsletter_container
	{
		width: 667px;
	}
}

/************
4. 1100px
************/

@media only screen and (max-width: 1100px)
{
	
}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px)
{
	
}

/************
6. 991px
************/

@media only screen and (max-width: 991px)
{
	.main_nav
	{
		display: none;
	}
	.log_reg
	{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 160px;
	}
	.hamburger
	{
		display: block;
	}
	.featured_album .section_title_container
	{
		padding-left: 0;
	}
	.featured_album_image
	{
		width: calc(100% + 242px);
	}
	.featured_album_player_container
	{
		width: calc(100% + 158px);
		    left: -158px;
	}
	.featured_album_title_bar
	{
		padding-left: 35px;
		padding-right: 35px;
	}
	.jp-playlist li
	{
		padding-left: 35px;
	}
	.jp-playlist li > div
	{
		padding-right: 35px;
	}
	.featured_album_link
	{
		width: 167px;
	}
	.shows_image
	{
		left: auto;
		width: 100%;
	}
	.shows_list_container
	{
		width: 100%;
		top: auto;
		-webkit-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		-o-transform: none;
		transform: none;
		padding-left: 15px;
		padding-right: 15px;
	}
	.shows_list_col
	{
		position: absolute;
		top: 50%;
		left: 0;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.shows_list li
	{
		font-size: 16px;
	}
	.shows_row
	{
		margin-top: 71px;
	}
	.artist_image_col
	{
		position: absolute;
	    width: 100%;
	    right: 0;
	    top: 50px;
	    text-align: right;
	}
	.artist_image
	{
		left: auto;
		padding-right: 0;
		width: 100%;
	}
	.artist_image img
	{
		max-width: 46%;
		opacity: 0.5;
	}
}

/************
7. 959px
************/

@media only screen and (max-width: 959px)
{
	
}

/************
8. 880px
************/

@media only screen and (max-width: 880px)
{
	
}

/************
9. 768px
************/

@media only screen and (max-width: 768px)
{
	
}

/************
10. 767px
************/

@media only screen and (max-width: 767px)
{
	.featured_album_image
	{
		width: 100%;
		height: 272px;
	}
	.featured_album_player_container
	{
		width: 100%;
		left: auto;
	}
	.shows_list li
	{
		font-size: 14px;
		padding-right: 15px;
	}
	.show_name
	{
		width: 100%;
	}
	.show_location
	{
		width: 100%;
		margin-left: 0;
	}
	.artist_image_col
	{
		top: 130px;
	}
	.artist_image img
	{
		max-width: 54%;
	}
	.extra_title h1
	{
		font-size: 48px;
	}
	.footer_lists
	{
		width: 100%;
	}
	.newsletter_container
	{
		width: 100%;
	}
}

/************
11. 575px
************/

@media only screen and (max-width: 575px)
{
	h1
	{
		font-size: 36px;
	}
	.home
	{
		height: 80vh;
		padding-left: 15px;
		padding-right: 15px;
	}
	.SearchArea {
		/* padding-left: 15px;
		padding-right: 15px; */
		position: absolute;
		/* right: 50px; */
	}
	
	.home_title h1
	{
		font-size: 65px;
		text-shadow: 3px 2px #da29f7;
	}
	.Youtube_video_section{
		padding-left: 15px !important;
		padding-right: 15px !important;
	}
	.header_content
	{
		padding-left: 15px;
		padding-right: 15px;
		height: 60px;
	}
	.logo
	{
		left: 15px;
	}
	.logo img
	{
		width: 50px !important;
	}

	.log_reg
	{
		display: block;
	}
	.menu_log_reg
	{
		display: block;
	}
	.featured_album_artist
	{
		font-size: 22px;
	}
	.featured_album_title
	{
		font-size: 14px;
		margin-top: 2px;
	}
	.jp-type-playlist div.jp-playlist a,
	.song_duration
	{
		font-size: 14px !important;
	}
	.jp-playlist li
	{
		padding-left: 25px;
	}
	.jp-playlist li > div
	{
		padding-right: 25px;
	}
	.featured_album_title_bar
	{
		padding-left: 25px;
		padding-right: 25px;
	}
	.show_date
	{
		width: 68px;
	}
	.show_shop
	{
		width: 100px;
	}
	.single_player_container
	{
		width: 100%;
		left: auto;
	}
	.jp-type-single .player_details
	{
		padding-left: 20px;
		padding-right: 22px;
	}
	.jp-type-single .player_controls .jp-interface
	{
		padding-left: 20px;
		padding-right: 22px;
	}
	.extra
	{
		padding-left: 15px;
		padding-right: 15px;
	}
	.footer
	{
		padding-left: 15px;
		padding-right: 15px;
	}
	.extra_title h1
	{
		font-size: 36px;
	}
	.newsletter_input
	{
		height: 40px;
	}
	.newsletter_button
	{
		width: 109px;
		height: 40px;
	}
	.newsletter_title h2
	{
		font-size: 24px;
	}
	.footer_list
	{
		width: 100%;
	}
	.footer_list:not(:last-child)
	{
		margin-bottom: 60px;
	}
}

/************
11. 539px
************/

@media only screen and (max-width: 539px)
{
	
}

/************
12. 480px
************/

@media only screen and (max-width: 480px)
{
	
}

/************
13. 479px
************/

@media only screen and (max-width: 479px)
{
	
}

/************
14. 400px
************/

@media only screen and (max-width: 400px)
{
	
}































/**/

.Youtube_video_section
{
	width: 100%;
    padding-left: 51px;
    padding-right: 52px;
}
.Vborder{
    border-bottom: 1px solid #333333;
    padding-bottom: 15px;
	/* border-top: 1px solid #333333; */
}
.vTitle {
	margin: 8px 0 8px;
}
.vTitle span{
	display: -webkit-box;
    max-height: 3.2rem;
    -webkit-box-orient: vertical;
	overflow: hidden;
	padding: 0px 5px;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    /* font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6rem; */
}
.vSubTitle{
	display: -webkit-box;
    max-height: 3.2rem;
    -webkit-box-orient: vertical;
    overflow: hidden;
	text-overflow: ellipsis;
	padding: 0px 5px;
    white-space: normal;
	-webkit-line-clamp: 2;
	font-size: 12px;
}
.Vviews::after{
	content: '•';
	margin: 0 5px;
}
.Youtube_video_section .owl-theme .owl-nav{
	background: none;
}
.Youtube_video_section .owl-nav {
	position: unset;
	margin-top: 0px;
}
.Youtube_video_section .owl-prev {
	position: absolute;
    left: -30px;
	top: 50%;
    transform: translate(50%, -50%);
}
.Youtube_video_section .owl-next {
	position: absolute;
    right: -30px;
    top: 50%;
    transform: translate(-50%, -50%);
}

.detailpage {
	margin-top: 115px;
}
.detailpage.Vborder{
	padding-top: 15px
}

.hborder{
	border-bottom: 1px solid #333333;
}
.video_title  {
	font-size: 18px;
}
.video_title  span {
	border-bottom: 2px solid #e9a911;
	padding-bottom: 2px;
}
.iframe_height {
	height: 100%;
}

.owl-theme .owl-nav [class*='owl-'] {
    background: #e9a911;
}
.owl-theme .owl-nav [class*='owl-']:hover {
    background: rgba(233, 169, 17, 0.78);
}
.SearchArea .form-control {
	border: 1px solid #e9a911;
	background-color: rgb(31, 31, 31);
}
.SearchArea  .btn-secondary {
    color: #fff;
    background-color: #e9a911;
    border-color: #e9a911;
}